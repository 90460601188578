import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect, updateAccount } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import NFTContainer from "./NFTContainer";
import styled from "styled-components";
import blockchainReducer from "./redux/blockchain/blockchainReducer";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: var(--secondary);
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--primary);
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;
export const ResponsiveWrapper2 = styled.div`
  display: grid;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;


export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 100%;
  height:100%;
  @media (min-width: 767px) {
    min-width: 100%;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
 
]
  border-radius: 100%;
  width: 600px;
  @media (min-width: 100%) {
    width: 100%;
  }
  @media (min-width: 100%) {
    width: 100%;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click buy to mint your NFT.`);
  if (typeof window.ethereum !== 'undefined') {
  }
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `Congratulations on your newly minted ${CONFIG.NFT_NAME}!`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 100) {
      newMintAmount = 100;
    }
    setMintAmount(newMintAmount);
  };

  const [nfts, setNfts] = useState([])

  const getData = async () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account))
    }

  }

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);

  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    <s.Screen>
      <s.Container
        flex={1}
        ai={"center"}
        style={{ padding: 5, backgroundColor: "var(--primary)" }}
        image={CONFIG.SHOW_BACKGROUND ? "/config/images/bg.png" : null}
        width="100%"
      >
        <StyledLogo alt={"logo"} src={"/config/images/logo.png"} 
        width="100%"/>
        <s.SpacerSmall />
        <ResponsiveWrapper flex={1} style={{ padding: 5 }} test>
          
            <img alt={"example"} src={"/config/images/limg.png"}
            width="100%"
            
             />
          
          <s.SpacerLarge />
          <s.Container
            flex={1}
            jc={"center"}
            ai={"center"}
            style={{
              minWidth: 340,
              backgroundColor: "var(--accent)",
              padding: 10,
              borderRadius: 24,
              border: "2px solid var(--secondary)",
              boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
            }}
          ><s.TextTitle
          style={{
            textAlign: "center",
            fontSize: 50,
            fontWeight: "bold",
            paddingBottom: 40,
            color: "var(--accent-text)",
          }}
        >
          Minting is live!
        </s.TextTitle>
            <div style={{
            textAlign: "center",
            fontSize: 20,
            
            color: "var(--accent-text)",
          }}>Connect to view total minted.</div>
            <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 50,
                fontWeight: "bold",
                color: "var(--accent-text)",
              }}
            >
              {data.totalSupply} / {CONFIG.MAX_SUPPLY}
            </s.TextTitle>
            <s.TextDescription
              style={{
                textAlign: "center",
                color: "var(--primary-text)",
              }}
            ><font color="white">BSCscan : </font>
            <StyledLink target={"_blank"} href={CONFIG.SCAN_LINK}>
                {truncate(CONFIG.CONTRACT_ADDRESS, 15)}
              </StyledLink>
              
            </s.TextDescription>
            <s.SpacerSmall />
            {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
              <>
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  The sale has ended.
                </s.TextTitle>
                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  You can find {CONFIG.NFT_NAME} on
                </s.TextDescription>
                <s.SpacerSmall />
                <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                  {CONFIG.MARKETPLACE}
                </StyledLink>
              </>
            ) : (
              <>
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  1 {CONFIG.SYMBOL} costs {CONFIG.DISPLAY_COST}{" "}
                  {CONFIG.NETWORK.SYMBOL}.
                </s.TextTitle>
                <s.SpacerXSmall />
                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  Excluding gas fees.
                </s.TextDescription>
                <s.SpacerSmall />
                {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <s.Container ai={"center"} jc={"center"}>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      Connect to the {CONFIG.NETWORK.NAME} network
                    </s.TextDescription>
                    <s.SpacerSmall />
                    <StyledButton
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                      CONNECT
                    </StyledButton>
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      {feedback}
                    </s.TextDescription>
                    <s.SpacerMedium />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledRoundButton
                        style={{ lineHeight: 0.4 }}
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          decrementMintAmount();
                        }}
                      >
                        -
                      </StyledRoundButton>
                      <s.SpacerMedium />
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        {mintAmount}
                      </s.TextDescription>
                      <s.SpacerMedium />
                      <StyledRoundButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          incrementMintAmount();
                        }}
                      >
                        +
                      </StyledRoundButton>
                    </s.Container>
                    <s.SpacerSmall />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          claimNFTs();
                          getData();
                        }}
                      >
                        {claimingNft ? "BUSY" : "BUY"}
                      </StyledButton>
                    </s.Container>
                    
                  </>
                )}
              </>
            )}
            <s.SpacerMedium />
          </s.Container>
          <s.SpacerLarge />
          
            <img
              alt={"example"}
              src={"/config/images/rimg.png"}
              width="100%"
              style={{ transform: "scaleX(-1)" }}

            />
          
        </ResponsiveWrapper>
        <s.Container jc={"center"} ai={"center"} style={{ width: "90%" }}>
        <s.TextDescription
                  style={{ textAlign: "center", 
                  color: "var(--primary-text)", 
                  fontSize: 30,
              }}
                >
                 Connected Address : {blockchain.account}
                </s.TextDescription>
          <s.TextDescription
            style={{
              textAlign: "center",
              color: "var(--primary-text)",
            }}
          >
            Please make sure you are connected to the right network (
            {CONFIG.NETWORK.NAME}) and the correct address. Please note:
            Once you confirm purchase, you cannot undo this action.
          </s.TextDescription>
          <div>
          
          
          </div>
          <div>
          </div>

          <s.SpacerLarge />
          <s.SpacerLarge />
          <div>
            <img src= {"/config/images/wimg.png"}
            width="100%" />
          </div>
          <s.SpacerSmall />
          <div
                style={{ 
                  textAlign: "center", 
                  color: "var(--primary-text)", 
                  fontSize: 30,
                  fontWeight: "bold",
                }}>
                  CatBonkers are a collection of 7999 completely unique feline friends who love bonking your favorite dog coins! CatBonkers are randomly generated from 79 different traits and stored forever on IPFS.
                  <s.SpacerSmall/>
                  <s.SpacerSmall/>
                  <s.SpacerSmall/>
                  Traits range from common rarity all the way up to hyper rare! Hunt for the rarest traits or hunt to bonk a Doge, Floki, Hoge, or Shiba! No dog is safe! 
                  <s.SpacerSmall/>
                  <s.SpacerSmall/>
                  <s.SpacerSmall/>
                  CatBonkers are the Official NFT of CatBonk, the first feline bonking meme coin on the Binance Smart Chain. We are excited to explore creative methods to balance
                  the relationship between $CABO and CatBonkers and to establish future utility including brand merch, gaming, lottery, and metaverse!
                  <s.SpacerSmall/>
                  <s.SpacerSmall/>
                  <s.SpacerSmall/>
                  Be sure to follow us on <StyledLink href={"https://twitter.com/CatBonk1"}> Twitter </StyledLink> and stay up to date with our <StyledLink href={"https://catbonk.medium.com"}> Medium </StyledLink> articles. Join our community in <StyledLink href={"https://discord.gg/Jm3sfuktWq"}> Discord</StyledLink> where the first 1000 members will be given the "OG Bonker" role and exclusive Pre Sale access!
                  <s.SpacerSmall/>
                  <s.SpacerSmall/>
                </div>
                </s.Container>
                <s.SpacerSmall />
                <s.Container jc={"center"} ai={"center"} style={{ width: "100%" }}>
          <div>
            <img src={"/config/images/mimg.png"}
            width="100%" />
          </div>
          <s.SpacerSmall />
          <div>
            <img src= {"/config/images/dimg.png"}
            width="100%" />
          </div>
<s.SpacerLarge />
<s.SpacerLarge />
<div>
            <img src= {"/config/images/4vimg.png"}
            width="100%" />
          </div>
          <s.SpacerLarge />
<s.SpacerLarge />
            
<s.Container jc={"center"} ai={"center"}>
  <div>

            <img src={"/config/images/bimg.png"}
            width="100%"/>
            </div>
            <s.SpacerLarge />

            <div>
                 <div>
            <img src= {"/config/images/ppimg.png"} 
            width="100%"/>
          </div>
                </div>
                <div>
            <img src= {"/config/images/20img.png"}
            width="100%" />
          </div>
          <div>
            <img src= {"/config/images/30.png"} 
            width="100%"/>
          </div>
                <div>
            <img src= {"/config/images/40img.png"} 
            width="100%"/>
          </div>
           <div>
            <img src= {"/config/images/50img.png"}
            width="100%" />
          </div>
          <div>
            <img src= {"/config/images/60img.png"}
            width="100%" />
          </div>
                <div>
            <img src= {"/config/images/70img.png"}
            width="100%" />
          </div>
                <div>
            <img src= {"/config/images/80img.png"}
            width="100%" />
          </div>
          <div>
            <img src= {"/config/images/90img.png"}
            width="100%" />
          </div>
          <div>
            <img src= {"/config/images/100img.png"}
            width="100%" />
          </div>
          <s.SpacerSmall/>
                  <s.SpacerSmall/>
<div>
            <img src= {"/config/images/aimg.png"}
            width="100%" />
          </div>
               <s.SpacerMedium />
               <s.SpacerSmall/>
                <div>
            <img src= {"/config/images/4img.png"}
            width="100%" />
          </div>
          <s.SpacerMedium />
          <s.SpacerMedium />
          <div
                style={{ 
                  textAlign: "center", 
                  color: "var(--primary-text)", 
                  fontSize: 60,
                  fontWeight: "bold",
                }}>
                  CatBonker Rarity Guide</div>
                  <s.SpacerMedium />
          <s.SpacerMedium />
          <div
                style={{ 
                  textAlign: "center", 
                }}>
          <img src={"/config/images/rarity.png"}
          width="100%"/>
          </div>
          <s.SpacerLarge />
          <s.SpacerLarge />
          <div
                style={{ 
                  textAlign: "center", 
                  color: "var(--primary-text)", 
                  fontSize: 40,
                  fontWeight: "bold",
                }}>
                  Become a CatBonker and join us as we explore new utilities and develop an exciting symbiotic relationship between $CABO and CatBonkers.
                  The dogs have had their day, it's time for cats to show who's boss!
                  <s.SpacerMedium />
          <s.SpacerMedium />
                </div>
                
                <div>
                <a href="https://discord.gg/Jm3sfuktWq">
                <img src={"/config/images/disc.png"} width="60"/>
                </a>
                <a href="https://twitter.com/CatBonk1">
                <img src= {"/config/images/twit.png"} width="60"/>
                </a>
                <a href="https://catbonk.medium.com/">
                <img src= {"/config/images/med.png"} width="60"/>
                </a>
                <a href="https://t.me/catbonk_official">
                <img src= {"/config/images/tele.png"} width="60"/>
                </a>
                <a href="https://catbonk.com">
                <img src= {"/config/images/cabo.png"} width="60"/>
                </a>
                </div>
                

                </s.Container>
        </s.Container>
      </s.Container>
    </s.Screen>
  );
}

export default App;
